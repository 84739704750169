.awards-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
}

.awards-text-wrapper {
  display: flex;
  flex-direction: column;
}

.awards-title {
  font-size: 30px;
  font-weight: 600;
}

.awards-text {
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 1.4em;
  font-weight: 600;
  line-height: 20px;
  margin: 50px 0;
}

.awards-img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.awards-image {
  width: 90%;
  border-radius: 100px;
}

@media (min-width: 778px) {
  .awards-wrapper {
    padding: 30px 20px;
    margin: 25px 6.5%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .awards-text-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .awards-title {
    font-size: 3em;
    font-weight: 600;
  }

  .awards-text {
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 1.5em;
    font-weight: 600;
    line-height: 30px;
    margin: 20px 0;
  }

  .awards-img-wrapper {
    width: 33%;
    display: flex;
    justify-content: center;
  }

  .awards-image {
    width: 90%;
    border-radius: 50px;
  }
}
